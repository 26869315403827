<minster-modal [show]="state !== 0" (closed)="state = 0">
    <ng-container *ngIf="state === 1">
        <h2 class="c-heading c-heading-purple">Approving my medical report</h2>
        <div class="u-mtop-ism">
            <ul class="c-checklist">
                <li class="c-checlist__item" *ngFor="let check of checks">
                    <svg class="c-ic c-ic--sm c-ic-dark c-checlist__ic"> <use xlink:href="#ic-checklist" href="#ic-checklist"></use></svg>
                    <p class="c-checlist__text c-text-width c-text-dark">
                        {{check}}
                    </p>
                </li>
            </ul>
        </div>
        <form action="" (submit)="approve(approvalForm)">
            <div class="u-mtop-md">
                <input aria-label="confirm medical approval" class="c-styled-checkbox" type="checkbox" id="medical-approval-confirmation" [formControl]="approvalForm.controls['confirmation']">
                <label for="medical-approval-confirmation">I have read and agree with all information stated in my Medical Report.</label>
                <ng-container *ngIf="submitted">
                    <minster-validation-message *ngIf="approvalForm.controls.confirmation.hasError('required')" message="* You must tick to confirm you have read and approve the information stated in your medical report."></minster-validation-message>
                </ng-container>
            </div>
            <div class="u-flex-center  u-mtop-sm">
                <minster-button buttonClass="u-mright-sm" ariaLabel="Confirm medical report" buttonType="submit" [disabled]="isLoading" id="medical-confirm-submit-button" text="Confirm" isPrimary="true" [isLoading]="isLoading ? true : false"></minster-button>
                <minster-button ariaLabel="Cancel confirmation of medical report" buttonType="button" (click)="close()" [disabled]="isLoading" id="medical-confirm-cancel-button" text="Cancel" isInfo="true"></minster-button>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="state === 2">
        <div class="content">
            <h3 class="c-heading c-heading-purple">Approved</h3>
            <p class="c-text-width c-text-dark">Thank you for taking the time to review your medical report.</p>
            <div class="u-flex-center  u-mtop-sm">
                <minster-button buttonType="button" id="close-confirm-approved-button" (click)="close()" text="Close" isPrimary="true"></minster-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="state === -1">
        <h2 class="c-heading c-heading-purple">Rejecting your medical report</h2>
        <p class="c-text-width c-text-dark">Before confirming your rejection please ensure you have read your medical report and any attached documents carefully. You should only reject your report for one or more of the following reasons;</p>
        <div class="u-mtop-ism">
            <ul class="c-checklist">
                <li class="c-checlist__item" *ngFor="let check of checks">
                    <svg class="c-ic c-ic--sm c-ic-dark c-checlist__ic"> <use xlink:href="#ic-checklist" href="#ic-checklist"></use></svg>
                    <p class="c-checlist__text c-text-width c-text-dark">
                        {{check}}
                    </p>
                </li>
            </ul>
        </div>
        <p class="c-text-width c-text-dark">
            If you choose to reject your report we will contact you directly to discuss this in more detail. There is very little scope for amendment of the report particularly if the amendment relates to some information which you did not give to the doctor at the time of the examination. Please note that this may result in delays in progressing your claim.
        </p>
        <div class="u-flex-center  u-mtop-sm">
            <minster-button  buttonClass="u-mright-sm" buttonType="button" (click)="state = -2" [disabled]="isLoading" id="medical-confirm-understand-rejection-button" text="Continue" isPrimary="true" [isLoading]="isLoading ? true : false"></minster-button>
            <minster-button buttonType="button" (click)="close()" [disabled]="isLoading" id="medical-confirm-cancel-button" text="Cancel" isInfo="true"></minster-button>
        </div>
    </ng-container>

    <ng-container *ngIf="state === -2">
        <div class="content">
            <h2 class="c-heading c-heading-purple">Reason for rejection.</h2>
            <p class="c-text-width c-text-dark">Please enter your rejection reason below.</p>
            <p class="c-text-width c-text-dark">Your explanation must be between 10 and 1000 characters long.</p>
            <form action="" (submit)="reject(rejectionForm)">
                <div class="c-text-input u-mtop-ism">
                    <label class="u-text-bold" for="reason">Reason</label>
                    <textarea id="reason" id="rejection-reason-text-area" class="c-text-area" [formControl]="rejectionForm.controls['reason']" placeholder="Please provide your reasons for rejecting the report."></textarea>
                    <ng-container *ngIf="submitted">
                        <minster-validation-message *ngIf="rejectionForm.controls.reason.hasError('required')" message="Please provide a reason for rejecting the medical report."></minster-validation-message>
                        <minster-validation-message *ngIf="rejectionForm.controls.reason.hasError('minlength')" message="Your reason must be at least 10 characters long."></minster-validation-message>
                        <minster-validation-message *ngIf="rejectionForm.controls.reason.hasError('maxlength')" message="Your reason must be less than 1000 characters long."></minster-validation-message>
                    </ng-container>
                </div>
                <div class="u-flex-center  u-mtop-sm">
                    <minster-button buttonClass="u-mright-sm" ariaLabel="Reject medical report" buttonType="submit" [disabled]="isLoading" id="medical-rejection-submit-button" text="Reject" isPrimary="true" [isLoading]="isLoading ? true : false"></minster-button>
                    <minster-button ariaLabel="Cancel rejection of medical report" buttonType="button" (click)="close()" [disabled]="isLoading" id="medical-rejection-cancel-button" text="Cancel" isInfo="true"></minster-button>
                </div>
            </form>
        </div>
    </ng-container>

    <ng-container *ngIf="state === -3">
        <div class="content">
            <h2 class="c-heading c-heading-purple">Rejected</h2>
            <p class="c-text-width c-text-dark">Thank you for reviewing your medical report. We'll review your comments soon.</p>
            <div class="u-flex-center  u-mtop-sm">
                <minster-button buttonType="button" id="confirm-rejected-button" (click)="close()" text="Close" isPrimary="true"></minster-button>
            </div>
        </div>
    </ng-container>

</minster-modal>
