import { Component, OnInit } from '@angular/core';
import { CaseModel } from '../../models/case.model';
import { DocumentModel } from '../../models/document.model';
import { MedicalReportService, MedicalReportRequest } from '../../services/medical-report.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import medicalApproval from '../../data/medical-approval-checks.json';
enum ApprovalState {
    None = 0,
    RejectionStarted = -1,
    Rejection = -2,
    RejectionCompleted = -3,
    Approval = 1,
    ApprovalCompleted = 2,
}

@Component({
    selector: 'medical-report-approval',
    templateUrl: './medical-report-approval.component.html',
    styleUrls: ['./medical-report-approval.component.scss']
})
export class MedicalReportApprovalComponent implements OnInit {

    case: CaseModel;
    document: DocumentModel;
    approvalForm: UntypedFormGroup;
    rejectionForm: UntypedFormGroup;
    state: ApprovalState = ApprovalState.None;
    isLoading: boolean;
    submitted: boolean;
    checks = medicalApproval.approvalChecks;

    constructor(private medicalReportService: MedicalReportService, private formBuilder: UntypedFormBuilder) {
        this.approvalForm = this.formBuilder.group({
            'confirmation': [false, Validators.compose([Validators.requiredTrue])]
        });

        this.rejectionForm = this.formBuilder.group({
            'reason': ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(1000)])]
        });
    }

    ngOnInit(): void {
        this.medicalReportService.approvalCancelled.subscribe(() => {
            this.reset();
        });

        this.medicalReportService.rejectionCancelled.subscribe(() => {
            this.reset();
        });

        this.medicalReportService.approvalStarted.subscribe((request: MedicalReportRequest) => {
            this.reset();
            this.checks = medicalApproval.approvalChecks;
            this.case = request.case;
            this.document = request.document;
            this.state = ApprovalState.Approval;
        });

        this.medicalReportService.rejectionStarted.subscribe((request: MedicalReportRequest) => {
            this.reset();
            this.checks = medicalApproval.rejectionChecks;
            this.case = request.case;
            this.document = request.document;
            this.state = ApprovalState.RejectionStarted;
        });

        this.medicalReportService.approvalCommitted.subscribe((request: MedicalReportRequest) => {
            this.isLoading = false;
            this.reset();
            this.case = request.case;
            this.document = request.document;
            this.state = ApprovalState.ApprovalCompleted;
        });

        this.medicalReportService.rejectionCommitted.subscribe((request: MedicalReportRequest) => {
            this.isLoading = false;
            this.reset();
            this.case = request.case;
            this.document = request.document;
            this.state = ApprovalState.RejectionCompleted;
        });
    }

    approve(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.isLoading = true;

        this.medicalReportService.commitApproval(this.document, this.case).subscribe(() => {
            this.isLoading = false;
            form.enable();
        });
    }

    reject(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.isLoading = true;

        this.medicalReportService.commitRejection(this.document, this.case, form.value.reason).subscribe(() => {
            this.isLoading = false;
            form.enable();
        });
    }

    close() {
        if (this.state === ApprovalState.Approval) {
            this.medicalReportService.cancelApproval(this.document, this.case);
        }

        if (this.state === ApprovalState.Rejection) {
            this.medicalReportService.cancelRejection(this.document, this.case);
        }

        this.reset();
    }

    reset() {
        this.submitted = false;
        this.approvalForm.setValue({ confirmation: false });
        this.rejectionForm.setValue({ reason: '' });
        this.case = null;
        this.document = null;
        this.state = ApprovalState.None;
    }
}
